<template>
<div class="sm:block hidden">
  <el-dialog v-model="reportQuestionSessionModal" title="Signaler une question" class=" w-full" center :before-close="closeModal" destroy-on-close>
  
    <div class=" flex flex-col items-center text-center text-sm px-3">   
      
        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
            <div class="w-full">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Raison du signalement</p>
                </div>
                <el-select v-model="reportType" placeholder="Veuillez sélectionner la raison la plus adaptée" class="w-full">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                </el-select>
            </div>      
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
            <div class="w-full">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Remarques</p>
                </div>
                <el-input  :autosize="{ minRows:6, maxRows: 8 }" type="textarea" v-model="remark" class="w-full" placeholder="Veuillez laisser une remarque s'il n y a aucune raison adaptée dans le champs de sélection" />
            </div>      
        </div>
      
    </div>


    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="confirmeReport" class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Confirmer
        </button>
        
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block">
  <el-dialog v-model="reportQuestionSessionModal" width="85%" title="Signaler une question" class=" w-full" center :before-close="closeModal" destroy-on-close>
  
    <div class=" flex flex-col items-center text-center text-sm px-3">   
      
        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
            <div class="w-full">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Raison du signalement</p>
                </div>
                <el-select v-model="reportType" placeholder="Veuillez sélectionner la raison la plus adaptée" class="w-full">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                </el-select>
            </div>      
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
            <div class="w-full">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Remarques</p>
                </div>
                <el-input  :autosize="{ minRows:6, maxRows: 8 }" type="textarea" v-model="remark" class="w-full" placeholder="Veuillez laisser une remarque s'il n y a aucune raison adaptée dans le champs de sélection" />
            </div>      
        </div>
      
    </div>


    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="confirmeReport" class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Confirmer
        </button>
        
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import axiosIns from '../../../plugins/axios';
export default {
  data () {
    return {
      
      reportType : null,
      remark : null,
      options: [
        {
          value: 'La réponse de cette question est erronée',
          label: 'La réponse de cette question est erronée',
        },
        {
          value: "L'explication de cette question est inconvenable",
          label: "L'explication de cette question est inconvenable",
        },
        {
          value: 'La question ne correspond pas à ce cours',
          label: 'La question ne correspond pas à ce cours',
        },
        {
          value: 'Il y a une erreur de grammaire',
          label: "Il y a une erreur de grammaire",
        },
        {
          value: 'Aucune de ces réponses',
          label: "Aucune de ces réponses",
        },
      ],

    }

  },
  
  props:{
    reportQuestionSessionModal : Boolean,
    selectedIdType: Object,
    closeReportQuestion: Function
  },

  methods: {
    closeModal(){
        this.closeReportQuestion();
    },

    confirmeReport(){

      axiosIns.post("/report",
      {
        user_id: this.$store.getters.get_user.id,
        id_question: this.selectedIdType.id,
        type: this.reportType,
        comment: this.remark,
      })
      .then(() => {
        this.closeReportQuestion();
      })
      .catch(() => {
        
      });
    }

  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  },

}
</script>

<style>

</style>