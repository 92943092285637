<template>
<div class="sm:block hidden">
  <el-dialog v-model="deleteQuestionFromPlaylistDialogVisible" title="Supprimer la question de cette playlist" width="40%" center :before-close="closeModal" destroy-on-close>
       <el-result
            icon="info"
            title="Attention"
            subTitle="Voulez-vous vraiment supprimer cette question de cette playlist ?"
        >
        </el-result>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-5 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="deleteQuestionPlaylist" class="py-2 px-4 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Supprimer
        </button>
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block">
  <el-dialog v-model="deleteQuestionFromPlaylistDialogVisible" title="Supprimer la question de cette playlist" width="85%" center :before-close="closeModal" destroy-on-close>
       <el-result
            icon="info"
            title="Attention"
            subTitle="Voulez-vous vraiment supprimer cette question de cette playlist ?"
        >
        </el-result>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-5 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="deleteQuestionPlaylist" class="py-2 px-4 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Supprimer
        </button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import axiosIns from '../../../plugins/axios';

export default {
  data () {
    return {

    }
  },
  
  props:{
    deleteQuestionFromPlaylistDialogVisible : Boolean,
    closeDeleteQuestionFromPlaylistModal: Function,
    closeDeleteQuestionFromPlaylistModalWithdelete: Function,
    selectedIdType : Object,
    idSession: Number
  },



  methods: {
    closeModal(){
      this.closeDeleteQuestionFromPlaylistModal()
    },

    deleteQuestionPlaylist(){
      axiosIns.delete("/deletequestionsessionplaylist/"+this.idSession+"/"+this.selectedIdType.id+"/"+this.selectedIdType.type)
        .then(() => {
          this.isLoading = false;
          this.closeDeleteQuestionFromPlaylistModalWithdelete();
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
  },

}
</script>

<style>

</style>